<template>
  <VanPopup
    v-model="isShow"
    style="width: 93%; max-width: 400px"
    round
    @click-overlay="closePop"
  >
    <div class="wrap">
      <div class="title-wrap">
        <div class="title">查看我的投票权</div>
        <div class="close-icon" @click="closePop">✕</div>
      </div>
      <div class="content">
        <div class="select">我的投票权</div>
        <div class="num">3412412412</div>
        <div class="des">
          您的投票权取决你的钱包地址在BSC区块高度：564823645中xMEB的持仓与DAO质押之和
        </div>
        <div class="my-rights">
          <div class="title">我的投票权</div>
          <div class="key-value">
            <div class="key">xMEB持仓</div>
            <div class="value">312312421</div>
          </div>
          <div class="key-value">
            <div class="key">DAO质押</div>
            <div class="value">312312421</div>
          </div>
        </div>
        <div class="cofirm-button" @click="closePop">我知道了</div>
      </div>
    </div>
  </VanPopup>
</template>

<script>
import config from '@/config/app.config'
const contractsInfo = config.contractsInfo
export default {
  name: 'MyVoteRightsPop',
  data () {
    return {
      tokenMeb: contractsInfo.tokenGEPContract,
      mebBalance: 0,
      mebValue: 0,
      isShow: true
    }
  },
  methods: {
    closePop () {
      this.$emit('close')
    },
    async getMebBalance () {
      const mebResp = await this.$web3.balanceOf(this.tokenMeb)
      if (mebResp.success) {
        this.mebBalance = this.$gbUtils.formatTwoBalance(mebResp.result) || 0
      }
    }
  },
  mounted () {
    this.getMebBalance()
  }
}
</script>

<style scoped lang="scss">
.wrap {
  font-size: 12px;
  .title {
    font-weight: bold;
  }
  .line {
    height: 1px;
    background-color: #e3e3e3;
  }
  .title-wrap {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bold;
    border-bottom: 1px solid #e3e3e3;
    .close-icon {
      background-color: #000 !important;
      font-size: 14px;
    }
  }
  .content {
    padding: 15px 25px;
    .select {
      font-weight: bold;
      font-size: 12px;
    }
    .num {
      font-size: 20px;
      font-weight: bold;
      padding: 10px 0;
      border-bottom: 1px solid #e3e3e3;
    }
    .des {
      padding: 15px 0;
      color: #818086;
      line-height: 1.2rem;
    }
  }
  .my-rights {
    background: #f0f0f0;
    padding: 20px;
    border-radius: 10px;
    .title {
      font-size: 17px;
    }
    .key-value {
      padding-top: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .key {
        font-weight: bold;
      }
    }
  }
  .cofirm-button {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: bold;
    height: 50px;
    line-height: 50px;
    background: #000;
    color: #fff;
    text-align: center;
    border-radius: 10px;
  }
}
</style>
