<template>
  <div class="wrap">
    <svg class="bnb-icon" aria-hidden="true">
      <use xlink:href="#icon-a-zu206"></use>
    </svg>
    <div class="content">
      <div class="title">请输入提案标题</div>
      <input type="text" class="input-box" />
      <div class="title">请选择提案分类</div>
      <van-dropdown-menu
        :overlay="false"
        active-color="#000"
        style="margin-bottom: 20px; width: 100%"
      >
        <van-dropdown-item v-model="proposalType" :options="proposalTypeList" />
      </van-dropdown-menu>

      <div class="title">请输入提案内容</div>
      <textarea cols="30" rows="10" class="textarea-box"></textarea>
      <div class="planbox" v-for="(item, index) in planList" :key="index">
        <div class="title">请输入方案{{ index + 1 }}</div>
        <input type="text" class="input-box" />
      </div>
      <div class="add-plan-btn" @click="handleAddPlan">+ 添加选项</div>
    </div>
    <div class="info-box">
      <div class="title">提案信息</div>
      <div class="key-value">
        <div class="title">提案发起人</div>
        <div class="value">0x213123…123</div>
      </div>
      <div class="des">
        所有xMEB持有用户均可提交社区提案，获得超过5为理事支持的提案将成为治理提案，进行社区公投
      </div>
      <div class="key-value">
        <div class="title">BSC快照区块</div>
        <div class="value">412312412312</div>
      </div>
      <div class="notice">*您需要至少 100000 投票权才能提交填</div>
      <div class="confirm-button">确定投票</div>
      <div class="check">
        <span @click="handleShowVote">查看我的投票权</span>
      </div>
    </div>
    <MyVoteRightsPop
      v-if="rightsShow"
      @close="handleShowVote"
    ></MyVoteRightsPop>
  </div>
</template>

<script>
import MyVoteRightsPop from './components/MyVoteRightsPop.vue'
export default {
  name: 'ApplyProposal',
  data () {
    return {
      rightsShow: false, // 我的投票权
      proposalType: 1, // 提案类型
      proposalTypeList: [
        { text: '参数调整', value: 1 },
        { text: '技术方案', value: 2 },
        { text: '经费申请', value: 3 }
      ],
      planList: [ // 提案列表
        {
          content: ''
        }
      ]
    }
  },
  methods: {
    handleAddPlan () {
      this.planList.push({ content: '' })
    },
    handleShowVote () {
      this.rightsShow = !this.rightsShow
    }
  },
  mounted () {
  },
  components: { MyVoteRightsPop }
}
</script>

<style scoped lang="scss">
.wrap {
  padding: 20px;
  margin-bottom: 50px;
  .bnb-icon {
    width: 110px;
    height: 30px;
    padding-left: 30px;
    // padding-bottom: 15px;
  }
  .content {
    margin-top: 20px;
    padding: 20px;
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    .title {
      font-weight: bold;
      font-size: 12px;
      padding-bottom: 10px;
    }
    .input-box {
      box-sizing: border-box;
      height: 50px;
      border: 1px solid #c1c1c1;
      background-color: #fff !important;
      width: 100%;
      border-radius: 10px;
      margin-bottom: 20px;
      text-align: left !important;
      padding-left: 10px;
    }
    .textarea-box {
      resize: none;
      padding-left: 10px;
      padding-top: 10px;
      width: 100%;
      height: 150px;
      border: 1px solid #c1c1c1;
      border-radius: 10px;
      margin-bottom: 20px;
    }
    /deep/ .van-dropdown-menu__bar {
      height: 60px;
      border-radius: 10px;
      box-shadow: none;
      background-color: #f0f0f0;
      /deep/ .van-dropdown-menu__title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        text-align: left !important;
        /deep/ span {
          display: block;
          width: 100%;
        }
      }
    }
    /deep/ .van-dropdown-menu__title {
      width: 93%;
    }
    /deep/ .van-dropdown-menu__item {
      justify-content: space-between;
    }
    /deep/ .van-dropdown-item {
      margin: 0 50px;
    }
    /deep/ .van-dropdown-item__option {
      background-color: #f0f0f0;
    }
    /deep/ .van-popup--top {
      border-radius: 10px;
    }
    /deep/ .van-dropdown-menu__title::after {
      border-color: transparent transparent #000000 #000000;
      opacity: 1;
    }
    .add-plan-btn {
      height: 50px;
      border: 1px solid #000000;
      text-align: center;
      line-height: 50px;
      font-weight: bold;
      font-size: 15px;
      border-radius: 10px;
    }
  }
  .info-box {
    margin-top: 20px;
    padding: 20px;
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    font-size: 12px;
    .title {
      font-size: 17px;
      font-weight: bold;
    }
    .key-value {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 0;
      .title {
        font-size: 12px !important;
      }
    }
    .des {
      color: #ed8080;
    }
    .notice {
      color: #818086;
      padding-bottom: 5px;
    }
    .confirm-button {
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 15px;
      font-weight: bold;
      height: 50px;
      line-height: 50px;
      background: #000;
      color: #fff;
      text-align: center;
      border-radius: 10px;
    }
    .check {
      margin-top: 20px;
      margin-bottom: 10px;
      text-align: center;
      cursor: pointer;
      color: #7b8fbb;
      span {
        border-bottom: 1px solid #7b8fbb;
      }
    }
  }
}
</style>
